import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import ContactPerson from "../../components/contactPerson";
import { GatsbyImage } from "gatsby-plugin-image";
import growIdeas from "../../assets/images/doodle-grow-ideas.svg";
import Hero from "../../components/hero";

const Culture = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "hero-culture.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      tools: file(relativePath: { eq: "tools.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 727
            placeholder: DOMINANT_COLOR
            layout: CONSTRAINED
          )
        }
      }
      kununu: file(relativePath: { eq: "kununu.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 364
            placeholder: DOMINANT_COLOR
            layout: CONSTRAINED
          )
        }
      }
      jannik: file(relativePath: { eq: "profiles/jannik-bischof.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
      }
      sliders: allFile(
        sort: { fields: relativePath }
        filter: { relativePath: { glob: "culture/culture-slide-*" } }
      ) {
        nodes {
          publicURL
        }
      }
    }
  `);

  return (
    <Layout lang="en" langPath="nav.culture.link">
      <Seo
        title="Your place to develop based in Hamburg"
        description="We are an owner-managed IT service provider based in Hamburg. Here you can really make a difference and show just how much creative excellence you have to offer."
        keywords="Agency, content management, Hamburg, software development, corporate culture, jobs"
      />
      <div className="parallax-translate bg-shape-vertigo"></div>
      <Hero
        headline="Welcome to Monday"
        subline="We are an owner-managed IT service provider based in Hamburg. Our spacious offices near the Fischmarkt give you a clear view of the Port of Hamburg — and your future."
        section="Culture"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Culture"
        />
      </Hero>
      <div className="container mt-3 mb-5 mb-md-6">
        <div className="row align-items-start">
          <div className="col-md-6 d-none d-sm-block">
            <img className="w-75" alt="Space to develop" src={growIdeas} />
          </div>
          <div className="col-md-6">
            <h2>Space to develop</h2>
            <p>
              We naturally want our customers to achieve their goals. But how we
              get there is just as important for all of us at Monday. Which is
              why we’ve created a culture based on cooperation and the courage
              to not fear failure — because setbacks just make us stronger. At
              Monday, you can really make a difference and show just how much
              creative excellence you have to offer.
            </p>
            <p>
              This attitude is also reflected in our employee satisfaction. We
              achieved outstanding results on the Kununu rating platform.In
              2024, we again received the Kununu Top Company Award, which
              underscores our commitment to the well-being of our employees.
            </p>
            <GatsbyImage
              image={images.kununu.childImageSharp.gatsbyImageData}
              alt="Kununu Top Company Award"
              title="Kununu Top Company Award"
            />
            <br />
            <Link
              to="/en/jobs"
              className="btn bg-monday-skyblue text-color-white mt-3"
            >
              Go to vacancies
            </Link>
          </div>
        </div>
      </div>

      <div className="container mb-md-0">
        <div
          className="row vertical-spacing mb-3 reveal"
          data-spacing-bottom="team"
        >
          <h2 className="col-12 text-center">Good reasons to move to Monday</h2>
        </div>
        <div className="row align-items-start">
          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 bg-shape-cross bg-position-tl bg-size-50 vertical-offset"
            id="team"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                1
              </span>
              Your career
            </h3>
            <p>
              At Monday, you can take the initiative and take responsibility,
              while contributing and developing your own ideas. All the latest
              tools are used to underpin the quality of your work. And we also
              offer you made-to-measure coaching and professional development
              options. Above all, however, we celebrate your victories large and
              small — since all of us share in them, too.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 offset-1 pb-4 pb-md-0 mb-4 mb-md-6 bg-shape-dots bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                2
              </span>
              Your time
            </h3>
            <p>
              At Monday, you organize your own time and take responsibility for
              your work. Whether mobile at home or in our office at the
              Fischmarkt. Every month, you can decide how many hours you want to
              work. And if you build up a surplus, you can decide yourself: more
              money or more time off? It’s entirely up to you! On Fridays, we
              have a get-together to relax and talk about the events of the past
              week. Team events are also held regularly, such as our Annual
              Kick-Off and our monthly staff meeting. And at the weekend? We
              look forward to Monday!
            </p>
          </div>

          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 offset-md-1 bg-shape-circle bg-position-tl bg-size-50 vertical-offset"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                3
              </span>
              Your team
            </h3>
            <p>
              We strongly believe in providing a friendly environment, where you
              feel at home as part of a go-getting team. You set yourself tasks
              that allow you to grow, with experienced colleagues at your side
              who are always ready to share their know-how. Flat management
              structures and laid-back bosses? All part of the service.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 pb-4 pb-md-0 mb-4 offset-1 bg-shape-square bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                4
              </span>
              Your kind of thing
            </h3>
            <p>
              The big table in our Lounge is used to eat and celebrate together,
              while our ‘Think Tanks’ let you ponder things in peace. A proper
              coffee machine, table football and consoles are also all
              available. You also decide for yourself whether you’d rather work
              with Windows, macOS or Linux. Whatever works best for you.
            </p>
          </div>
        </div>
      </div>

      <div className="container mt-md-3 mb-5 mb-md-6 reveal">
        <div className="row align-items-center">
          <div className="col-md-6 mb-3">
            <GatsbyImage
              image={images.tools.childImageSharp.gatsbyImageData}
              className="w-75"
              alt="Tools we’ve come to love"
            />
          </div>
          <div className="col-md-6">
            <h3>Tools we’ve come to love</h3>
            <p>
              You can’t change the digital future with yesterday’s toolbox.
              Which is why you’ll work at Monday with the very latest tools for
              software and web application developers.
            </p>
            <Link
              to="/en/services"
              className="btn bg-monday-skyblue text-color-white"
            >
              Our services, your work
            </Link>
          </div>
        </div>
      </div>

      <div
        className="float-teaser float-teaser-right bg-monday-yellow vertical-offset reveal"
        data-offset="50%"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-6">
              <div className="float-teaser-body p-3 py-md-4 pl-lg-5">
                <h3 className="text-size-5">Right up your street?</h3>
                <p className="h4">
                  Make the move to Monday! Apply today and become part of the
                  team.
                </p>
                <Link to="/en/jobs" className="btn">
                  Current vacancies
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-5 fullwidth reveal">
        <div className="swiper-container" data-loop data-center>
          <div className="swiper-wrapper is-gallery">
            {images.sliders.nodes.map((image, i) => {
              return (
                <div className="swiper-slide col-8 col-md-auto" key={i}>
                  <img alt="Culture Gallery Slider" src={image.publicURL} />
                </div>
              );
            })}
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </div>

      <div
        className="content-container vertical-spacing bg-monday-skyblue bg-pattern-cross text-color-white text-center parallax-background"
        data-spacing-bottom="employees"
      >
        <div className="container pt-4 pt-md-5 pb-3">
          <div className="row">
            <div className="col-12 d-flex flex-column align-items-center mb-3">
              <h2>What people say who know Monday best of all</h2>
            </div>
          </div>
        </div>
      </div>

      <div
        id="employees"
        className="swiper-container container vertical-offset reveal mb-5 mb-md-6"
        data-offset="50%"
      >
        <div className="swiper-wrapper reveal">
          <div className="swiper-slide col-12 col-md-6 col-xl-4">
            <div className="p-3 bg-monday-yellow h-100 d-flex flex-column justify-content-between align-items-start">
              <div className="mb-3 w-100">
                <p>
                  The friendly and relaxed atmosphere at Monday is what I like
                  most. All of my colleagues help each other and share their
                  expertise. Our customers also generally see us as the
                  laid-back experts who get things done. We deliver as promised,
                  while also promoting our modern view of working life.
                </p>
              </div>
              <p className="text-sans w-100">
                Leon
                <br />
                Software Developer
              </p>
            </div>
          </div>
          <div className="swiper-slide col-12 col-md-6 col-xl-4">
            <div className="p-3 bg-monday-yellow h-100 d-flex flex-column justify-content-between align-items-start">
              <div className="mb-3 w-100">
                <p>
                  The best thing about Monday for me is the communication. At
                  the Friday get-together, everyone shares their thoughts on the
                  week just past and, if a major project has just been
                  completed, there’s a ‘pizza presentation’ where the project
                  results are then showcased during the meal.
                </p>
              </div>
              <p className="text-sans w-100">
                Elena
                <br />
                Marketing Manager
              </p>
            </div>
          </div>
          <div className="swiper-slide col-12 col-md-6 col-xl-4">
            <div className="p-3 bg-monday-yellow h-100 d-flex flex-column justify-content-between align-items-start">
              <div className="mb-3 w-100">
                <p>
                  I really like the flexible approach to working hours. If I
                  work a few more hours in one particular month, I can either
                  have the overtime worked paid out directly or can roll it over
                  as time off in another month. I feel that this makes a big
                  difference to job motivation.
                </p>
              </div>
              <p className="text-sans w-100">
                Jonas
                <br />
                Software Developer
              </p>
            </div>
          </div>
        </div>
        <div className="swiper-pagination d-xl-none"></div>
      </div>
      <ContactPerson
        headline="We look forward to hearing from you."
        text="Got what it takes? Likeable and easy-going? Know how customers tick? Perfect. Then we need to talk. These are just the qualities we’re looking for in our team."
        contactEmail="jobs@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Jannik Bischof, Lead Recruiter"
      >
        <GatsbyImage
          image={images.jannik.childImageSharp.gatsbyImageData}
          alt="Jannik Bischof"
        />
      </ContactPerson>
    </Layout>
  );
};

export default Culture;
